import React from 'react';
import { Measurement, Range, RangeLevel } from './HealthTrackerConstants';
import heartPoor from '../../images/health-tracker/heart-poor.png';
import heartFair from '../../images/health-tracker/heart-fair.png';
import heartAverage from '../../images/health-tracker/heart-average.png';
import heartGood from '../../images/health-tracker/heart-good.png';
import heartExcellent from '../../images/health-tracker/heart-excellent.png';
import biometricsPng from '../../images/health-tracker/biometrics.png';

interface HealthScoreBiometricsLinksProps {
    measurements: Measurement[];
}

const heartIcons: { [key in RangeLevel]: string } = {
    Poor: heartPoor,
    Fair: heartFair,
    Average: heartAverage,
    Good: heartGood,
    Excellent: heartExcellent,
};

function getRangeLevel(value: number, ranges: Range[]): RangeLevel {
    for (const range of ranges) {
        if (value >= range.minValue && value <= range.maxValue) {
            return range.rangeLevel;
        }
    }
    return 'Poor';
}

const HealthScoreBiometricsLinks: React.FC<HealthScoreBiometricsLinksProps> = ({ measurements }) => {
    
    const signalsDictionary: Record<string, string> = {
        'BMI_CALC': 'Body Mass Index (BMI)',
        'BP_SYSTOLIC': 'Systolic Blood Pressure',
        'BP_DIASTOLIC': 'Diastolic Blood Pressure',
        'HR_BPM': 'Heart Rate',
        'BP_HEART_ATTACK': 'Heart Attack Risk'
    }
    
    return (
        <div className="md:py-md h-full">
            <div className="bg-lime-fg-light p-md h-full flex flex-col justify-center">
                <h2 className="text-sherpa-blue text-h2 mb-4">
                    <img src={biometricsPng} alt="Biometrics" className="inline me-2" /> Biometrics
                </h2>
                <div className="flex justify-between mb-5">
                    {(Object.keys(heartIcons) as RangeLevel[]).map((level) => (
                        <div
                            key={level}
                            className="flex flex-col xl:flex-row items-center space-y-1 sm:space-y-0 sm:space-x-1"
                        >
                            <img src={heartIcons[level]} alt={level} style={{ width: '21px', height: '20px' }} />
                            <span>{level}</span>
                        </div>
                    ))}
                </div>
                <div id="biometricActualLinks" className="mb-5">
                    {Object.keys(signalsDictionary).map((signalName: string) => {
                        const measurement: Measurement | undefined = measurements.find(
                            (m: Measurement) => m.keyName === signalName
                        );
                        console.log('SignalName ' + signalName);
                        console.log('measurement: ' + measurement?.keyName);
                        let heartIcon: string = heartPoor;
                        if (measurement) {
                            const rangeLevel: RangeLevel = getRangeLevel(
                                measurement.value,
                                measurement.ranges
                            );
                            heartIcon = heartIcons[rangeLevel] || heartPoor;
                        }

                        return (
                            <div key={signalName} className="mb-2 flex flex-row">
                                <img
                                    className="me-3"
                                    src={heartIcon}
                                    alt="heart"
                                    style={{width: '21px', height: '20px'}}
                                />
                                <span>{signalsDictionary[signalName]}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default HealthScoreBiometricsLinks;
